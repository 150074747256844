<template>
  <div>
    <Modal ref="modal" :title="'Salvar ' + name " name="Save" @ok="submit"
    okTitle="Salvar" okVariant="success" cancelVariant="secondary"
    >
      <div :style="{ opacity: loading ? '0.5' : '1', 'pointer-events': loading ? 'none' : 'auto' }">
        <p>Tem certeza que deseja salvar {{ state.name }}?</p>
      </div>
    </Modal>

    <!-- Loading -->
      <LoadingPage v-if="loading"></LoadingPage>
  </div>
</template>

<script>

import { request as api } from '@/services'
import Modal from '@/components/static/Modal'
import View from '@/mixins/core/ViewBehavior'

import LoadingPage from '@/components/static/LoadingPage'

export default {

  components: { Modal, LoadingPage },
  mixins: [View],
  data: () => ({
    loading: false
  }),
  computed: {
    requestObj () {
      return this.state.requests.add
    },
    dispatch () {
      return this.requestObj.dispatch
    }
  },
  methods: {
    submit () {
      if (this.dispatch) {
        const logs = []

        this.loading = true

        // Map Sample
        const keysSample = ['classe', 'corte', 'remessa', 'arranjo', 'origem', 'localidade', 'bucket']
        const itemList = this.state.items[0]

        // Send S3
        const requestConfig = {
          urn: '/analisar/send',
          sample: {
            classe: itemList.classe,
            corte: itemList.corte,
            remessa: itemList.remessa,
            arranjo: itemList.arranjo,
            origem: itemList.origem,
            localidade: itemList.localidade,
            bucket: itemList.bucket
          }
        }

        // Create Object
        const keyGroups = keysSample.map(key => itemList[key])
        console.log(keyGroups)
        const keyAnalise = keyGroups.join('.')

        // API
        api.postIot(requestConfig.urn, requestConfig.sample, response => {
          const urls = response.data.urls

          const request = this.state.requests.add
          const body = {
            key: keyAnalise,
            group_keys: keyGroups,
            user_id: this.$store.state.user.id,
            type: 'Análise Laboratório',
            description: 'Análise Completa',
            image: urls
          }

          // Create Object
          api[request.method](request.urn, body, response => {
            // Log success
            logs.push({ status: 'success', title: request.title, msg: request.success })

            this.$store.dispatch('samples/add', response.data.object, { root: true })

            this.$emit('finished', true, logs, response)

            // this.$router.push('/classificar/' + response.data.object.id)
            const objectId = response.data.object.id
            this.$router.push({
              name: 'classificar',
              params: {
                classe: itemList.classe,
                corte: itemList.corte,
                remessa: itemList.remessa,
                arranjo: itemList.arranjo,
                origem: itemList.origem,
                localidade: itemList.localidade,
                object_id: objectId
              }
            })
            this.loading = false
          },
          error => {
            // Log failure
            logs.push({ status: 'failed', title: 'Error ' + request.title, msg: error })
            this.$emit('finished', false, logs, response)
          })
        })
      }
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    mainAttr: {
      type: String,
      default: 'name'
    },
    request: {
      type: String,
      default: 'add'
    }
  }
}
</script>
